
import { apiService } from '../../../services/api.service'
import { Parameters } from './IParameters.interface'
import Swal from 'sweetalert2'
export default {
  computed: {
    parametersObjectToBeWatched () {
      return { ...this.parameters, ...this.cossaParameters }
    }
  },
  async created () {
    await this.getParameters()
  },
  data: () => ({
    valid: true,
    changedParameters: {},
    changedCossaParameters: {},
    parameters: {
      healthCheckInterval: 0,
      updateCheckInterval: 0,
      gpuCheckInterval: 0,
      cordatusServicesHealthCheckInterval: 0,
      tunnelServerHealthCheckInterval: 0,
      signalServerHealthCheckInterval: 0,
      synchronizeAlarmsInterval: 0,
      checkLicenceValidityInterval: 0,
      isTunnelServerAuthorizedWithoutLicence: false,
      isSignalServerAuthorizedWithoutLicence: false,
      notificationStatus: ''
    },
    cossaParameters: {
      cossaAttemptCount: 0,
      cossaInterval: 0
    },
    loading: false,
    rules: [(v) => !!v || 'Parameter is required']
  }),

  methods: {
    async getParameters () {
      try {
        this.loading = true
        const response = await apiService.get('/client-parameters')
        const parameters = response.data.parameters as Parameters

        const cossaParameters = {
          cossaAttemptCount: parameters.cossaAttemptCount,
          cossaInterval: parameters.cossaInterval
        }

        delete parameters.cossaAttemptCount
        delete parameters.cossaInterval
        this.parameters = { ...parameters }

        for (const parameter in cossaParameters) {
          this.cossaParameters[parameter] = cossaParameters[parameter]
        }
      } catch (error) {
        console.log('Error while getting parameters: ', error)
        Swal.fire('Warning!', 'Parameters could not be retrieved.', 'warning')
      } finally {
        this.loading = false
      }
    },
    async setChangedParameters () {
      await this.$store.dispatch('setChangedParameters', {
        ...this.changedParameters
      })
    },
    async updateParametersWithoutNotification () {
      await this.updateParameters(false, false)
    },
    async notifyWİthOldParameters () {
      try {
        const withClient = Object.keys(this.changedParameters).length > 0
        const withCossa = Object.keys(this.changedCossaParameters).length > 0

        if (withClient && withCossa) {
          const notificationConfig = await this.$store.dispatch(
            'notifyClients',
            {
              notifyParameters: true,
              notifyCossaParameters: true
            }
          )
          notificationConfig.status = 'Notifying'
          await this.$store.dispatch('updateNotificationStatus', {
            body: notificationConfig
          })
        } else if (withCossa) {
          const notificationConfig = await this.$store.dispatch(
            'notifyClients',
            {
              notifyParameters: false,
              notifyCossaParameters: true
            }
          )
          await this.$store.dispatch('updateNotificationStatus', {
            body: notificationConfig
          })
        } else if (withClient) {
          const notificationConfig = await this.$store.dispatch(
            'notifyClients',
            {
              notifyParameters: true
            }
          )
          notificationConfig.status = 'Notifying'
          await this.$store.dispatch('updateNotificationStatus', {
            body: notificationConfig
          })
        }
      } catch (error) {
        console.log('Error while notifying clients: ', error)
      } finally {
        this.getParameters()
      }
    },
    async updateParameters (shouldNotify = false) {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true

          await apiService.put('client-parameters', {
            ...this.changedParameters,
            ...this.changedCossaParameters,
            notificationStatus: shouldNotify
              ? 'Pending'
              : this.parameters.notificationStatus
          })

          if (shouldNotify) {
            await this.setChangedParameters()
          }
        } catch (error) {
          console.log('Error while updating parameters: ', error)
          Swal.fire('Warning!', 'Parameters could not be saved.', 'warning')
        } finally {
          this.loading = false
          this.getParameters()
        }
      }
    },
    async updateParametersAndNotify () {
      try {
        const changedClient = Object.keys(this.changedParameters).length > 0
        const changedCossa =
          Object.keys(this.changedCossaParameters).length > 0

        const shouldNotify = changedClient || changedCossa

        await this.updateParameters(shouldNotify)

        if (changedClient && changedCossa) {
          const notificationConfig = await this.$store.dispatch(
            'notifyClients',
            { notifyParameters: true, notifyCossaParameters: true }
          )
          notificationConfig.status = 'Notifying'

          await this.$store.dispatch('updateNotificationStatus', {
            body: notificationConfig
          })

          this.parameters.notificationStatus = 'Notifying'

          Swal.fire(
            'Success!',
            'Parameters are saved and both Client and COSSA are notified.',
            'success'
          )
        } else if (changedClient) {
          const notificationConfig = await this.$store.dispatch(
            'notifyClients',
            { notifyParameters: true, notifyCossaParameters: false }
          )
          notificationConfig.status = 'Notifying'

          await this.$store.dispatch('updateNotificationStatus', {
            body: notificationConfig
          })

          this.parameters.notificationStatus = 'Notifying'

          Swal.fire(
            'Success!',
            'Parameters are saved and Client is notified.',
            'success'
          )
        } else if (changedCossa) {
          const notificationConfig = await this.$store.dispatch(
            'notifyClients',
            { notifyParameters: false, notifyCossaParameters: true }
          )
          notificationConfig.status = 'Notifying'

          await this.$store.dispatch('updateNotificationStatus', {
            body: notificationConfig
          })

          Swal.fire(
            'Success!',
            'Parameters are saved and COSSA is notified.',
            'success'
          )
        }
      } catch (error) {
        console.log('Error while notifying:', error)
        Swal.fire('Warning!', 'Notification failed.', 'warning')
      }
    },
    isNumber (value) {
      return typeof value === 'number'
    },
    getUpdatedParameter (newParameters: Parameters, oldParameters: Parameters) {
      const updatedParams = {}

      for (const key in newParameters) {
        if (newParameters[key] !== oldParameters[key]) {
          if (!isNaN(parseInt(newParameters[key]))) {
            newParameters[key] = parseInt(newParameters[key])
          }
          updatedParams[key] = newParameters[key]
        }
      }

      return Object.keys(updatedParams).length > 0 ? updatedParams : null
    }
  },
  watch: {
    parametersObjectToBeWatched: {
      handler (newParameters, oldParameters) {
        delete newParameters.notificationStatus
        delete oldParameters.notificationStatus
        const cloneObj = JSON.parse(JSON.stringify(oldParameters))
        delete cloneObj.isSignalServerAuthorizedWithoutLicence
        delete cloneObj.isTunnelServerAuthorizedWithoutLicence
        // Check if all values of the oldParameters are 0 because of the initial page load
        const allOldParametersAreZero = Object.values(cloneObj).every(
          (value) => value === 0
        )
        if (allOldParametersAreZero) return

        const updatedParameter = this.getUpdatedParameter(
          newParameters,
          oldParameters
        )

        const cossaKeys = ['cossaAttemptCount', 'cossaInterval']
        const clientKeys = [
          'healthCheckInterval',
          'updateCheckInterval',
          'checkLicenceValidityInterval',
          'synchronizeAlarmsInterval',
          'signalServerHealthCheckInterval',
          'tunnelServerHealthCheckInterval',
          'gpuCheckInterval',
          'cordatusServicesHealthCheckInterval'
        ]

        const changedClientParams = {}
        const changedCossaParams = {}

        for (const key in updatedParameter) {
          if (clientKeys.includes(key)) {
            changedClientParams[key] = updatedParameter[key]
          } else if (cossaKeys.includes(key)) {
            changedCossaParams[key] = updatedParameter[key]
          }
        }

        if (!updatedParameter) return
        this.changedParameters = {
          ...this.changedParameters,
          ...changedClientParams
        }
        this.changedCossaParameters = {
          ...this.changedCossaParameters,
          ...changedCossaParams
        }
      },
      deep: true
    }
  }
}
